<template>
  <div class="Address ">
    <ul class="add">
      <li>
        <el-button
          type="primary"
          @click="toAdd"
        >新增发票信息</el-button>
      </li>
      <li>您已创建{{invoiceList.length}} 个发票信息，最多可创建25 个</li>
    </ul>
    <p
      style="line-height:45px;text-align:center"
      v-if='!invoiceList.length'
    > 您还没添加发票信息 </p>
    <div
      class="list "
      v-for="(item, index) in invoiceList"
      :key="index"
    >
      <div class="top">
        <ul>
          <li>电子普通发票</li>
          <li> <template v-if='item.invoice_code'> 企业</template> <template v-else> 个人</template></li>
          <li> {{item.invoice_title}} </li>
          <li><i
              class="default"
              v-if='item.status == 1'
            > 默认抬头</i></li>

        </ul>
        <ol class="themefont">
          <li @click="delect(item)">删除</li>
          <li @click="toEdit(item)">编辑</li>
          <li @click="defaultInvoice(item.invoice_id)">设为默认抬头</li>

        </ol>
      </div>
      <el-form
        label-width="120px"
        style='width:100%'
      >
        <el-form-item label="发票抬头类型">
          <span><template v-if='item.invoice_code'> 企业</template> <template v-else> 个人</template></span>
        </el-form-item>
        <el-form-item label="发票抬头名称">
          <span>{{item.invoice_title}} </span>

        </el-form-item>
        <el-form-item
          label="纳税人识别号"
          v-if=' item.invoice_code'
        >
          <span> {{ item.invoice_code }}</span>
        </el-form-item>
        <el-form-item
          label="单位地址"
          v-if=' item.invoice_goto_addr'
        >
          <span> {{ item.invoice_goto_addr }}</span>
        </el-form-item>
        <el-form-item
          label="单位电话"
          v-if=' item.invoice_reg_phone'
        >
          <span> {{ item.invoice_reg_phone }}</span>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="添加发票信息"
      :visible.sync="dialogVisible_add"
      width="70%"
    >

      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="ruleForm"
      >

        <el-form-item label="发票类型">
          普通发票
        </el-form-item>
        <el-form-item
          label="发票抬头类型"
          prop="type"
        >
          <el-radio
            v-model="ruleForm.type"
            label="1"
          >个人</el-radio>
          <el-radio
            v-model="ruleForm.type"
            label="2"
          >企业</el-radio>

        </el-form-item>

        <el-form-item
          label="发票抬头名称"
          prop="title"
        >
          <el-input v-model="ruleForm.title" />
        </el-form-item>

        <el-form-item
          label="纳税人识别号"
          prop="invoice_code"
          v-if='ruleForm.type==2'
        >
          <el-input v-model="ruleForm.invoice_code" />
        </el-form-item>
        <el-form-item
          label="更多选填项"
          v-if='ruleForm.type==2'
        >
          <el-collapse>
            <el-collapse-item
              title="填写单位地址、电话"
              name="1"
            >

              <el-row>
                <el-col :span="4">单位地址</el-col>
                <el-col :span="20">
                  <el-input v-model="ruleForm.address"></el-input>
                </el-col>
                <el-col :span="4">单位电话</el-col>
                <el-col :span="20">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-col>
              </el-row>

            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <el-form-item label="发票内容">
          商品明细
        </el-form-item>
        <el-form-item
          label="设为默认抬头"
          prop=""
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="ruleForm.status"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>

        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible_add = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addInvoice('ruleForm')"
        >提交</el-button>
      </span>

    </el-dialog>
    <el-dialog
      title="编辑发票信息"
      :visible.sync="dialogVisible"
      width="70%"
    >

      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="ruleForm"
      >

        <el-form-item
          label="发票类型"
          prop="receiver"
        >
          电子普通发票
        </el-form-item>

        <el-form-item
          label="发票抬头类型"
          prop="type"
        >
          <el-radio
            v-model="ruleForm.type"
            label="1"
          >个人</el-radio>
          <el-radio
            v-model="ruleForm.type"
            label="2"
          >企业</el-radio>
        </el-form-item>
        <el-form-item
          prop="title"
          label="发票抬头名称"
        >
          <el-input v-model="ruleForm.title" />
        </el-form-item>
        <el-form-item
          label="纳税人识别号"
          prop="invoice_code"
          v-if='ruleForm.type==2'
        >
          <el-input v-model="ruleForm.invoice_code" />
        </el-form-item>

        <el-form-item
          v-if='ruleForm.type==2'
          label="单位地址"
          prop="address"
        >
          <el-input v-model="ruleForm.address" />
        </el-form-item>
        <el-form-item
          v-if='ruleForm.type==2'
          label="单位电话"
          prop="address"
        >
          <el-input v-model="ruleForm.phone" />
        </el-form-item>
        <el-form-item
          label="发票内容"
          prop="address"
        >
          商品明细
        </el-form-item>
        <el-form-item
          label="设为默认抬头"
          prop=""
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="ruleForm.status"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>

        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="edit_submitForm('ruleForm')"
        >提交</el-button>
      </span>

    </el-dialog>

  </div>
</template>
<script>
// import VDistpicker from 'v-distpicker'
export default {
  data() {
    return {
      invoiceList: [],

      invoice_add: false,
      invoice_id: "",
      invoice: false,

      ruleForm: {
        type: "1",
        title: "",
        invoice_code: "",
        phone: "",
        address: "",
        status: "",
        id:''
      },
      rules: {
        type: [
          {
            required: true,
            message: "请选择抬头类型",
            trigger: "blur"
          }
        ],
        title: [
          {
            required: true,
            message: "请填写抬头名称",
            trigger: "blur"
          }
        ],
        invoice_code: [
          {
            required: true,
            message: "请填写纳税人识别号",
            trigger: "blur"
          }
        ]
      },

      dialogVisible_add: false,
      dialogVisible: false,

      showPopup: false,

      status: true
    };
  },
  methods: {
    edit_submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            member_id: this.$store.state.member.id,
            title: this.ruleForm.title,
            invoice_code: this.ruleForm.invoice_code,
            phone: this.ruleForm.phone,
            address: this.ruleForm.address,
            status: this.ruleForm.status
          };
          console.log()
          this.$put("home/invoice/" + this.ruleForm.id, data).then(() => {
            this.dialogVisible = false;
            this.$message("编辑成功");
            this.getInvoice();
          });
        }
      });
    },
    //新增地址
    toAdd() {
      this.ruleForm = { type: "1" };
      this.dialogVisible_add = true;
    },
    addInvoice(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            member_id: this.$store.state.member.id,
            title: this.ruleForm.title,
            invoice_code: this.ruleForm.invoice_code,
            phone: this.ruleForm.phone,
            address: this.ruleForm.address,
            status: this.ruleForm.status
          };
          this.$post("home/invoice", data).then(res => {
            this.dialogVisible_add = false;
            this.getInvoice();
          });
        }
      });
    },
    getInvoice() {
      this.$get("home/invoice", { member_id: this.$store.state.member.id }).then(res => {
        this.invoiceList = res.items.data;
        console.log(" this.invoiceList", this.invoiceList);
      });
    },

    defaultInvoice(id) {
      this.$put("home/invoice/" + id, {
        member_id: this.$store.state.member.id,
        status: 1
      }).then(res => {
        this.getInvoice();
      });
    },
    chose(item) {
      this.$router.push({
        path: "/order/submit",
        query: { noDefault: true }
      });
      sessionStorage.setItem("address", JSON.stringify(item));
    },

    //跳转编辑
    toEdit(item) {
      this.ruleForm = {
        type: "",
        title: "",
        invoice_code: "",
        phone: "",
        address: "",
        status: 0
      };
      this.dialogVisible = true;
      this.getInvoiceID(item.invoice_id);
    },
    getInvoiceID(id) {
      this.$get("home/invoice/" + id).then(res => {
        this.ruleForm.title = res.invoice_title;
        this.ruleForm.invoice_code = res.invoice_code;
        this.ruleForm.phone = res.invoice_reg_phone;
        this.ruleForm.address = res.invoice_goto_addr;
        this.ruleForm.type = res.invoice_code ? "2" : "1";
        this.ruleForm.status = res.status;
         this.ruleForm.id = res.invoice_id
      });
    },
    delect(item) {
      this.$confirm("确认删除选中地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let id = item.invoice_id;
          this.$del("home/invoice/" + id).then(res => {
            this.$message.success("删除成功");
            this.getInvoice();
          });
        })
        .catch(() => {
          this.$message.success({
            type: "info",
            message: "已取消删除"
          });
          this.getInvoice();
        });
    }
  },
  components: {},
  created() {
    this.getInvoice();
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/common";
@import "@/assets/styles/mixins";
.default {
  background: #e94c15;
  color: #fff;
  padding: 0 5px;
  border-radius: 5px;
}
::v-deep .ruleForm .el-form-item {
  padding: 15px;
}
::v-deep .ruleForm .el-form-item {
  padding: 15px;
}
.add::after {
  display: block;
  clear: both;
  content: "";
}
.add {
  line-height: 30px;
  margin-bottom: 20px;
  width: 100%;
  li {
    margin-right: 20px;
    float: left;
  }
}
::v-deep .el-form-item__label {
  line-height: 25px;
}
::v-deep .el-form-item__content {
  line-height: 25px;
}

.list {
  border: 1px solid #dedede;
  margin-bottom: 10px;
  width: 100%;

  padding: 20px;

  .top {
    @include space-between;

    ul {
      margin-bottom: 10px;
      width: 50%;

      li {
        display: inline-block;
        margin-right: 20px;

        font-weight: bold;
      }
    }
    ol {
      width: 50%;
      li:hover {
        cursor: pointer;
      }
      li {
        float: right;
        display: block;
        margin-left: 20px;
      }
    }
  }
}
.mine {
  height: 400px;

  .el-form-item {
    height: 70px;
    width: 410px;
  }
}
.mine .el-form-item:nth-child(3) {
  width: 750px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #e94c15;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px solid #ccc;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.Btn {
  padding: 10px 20px;
  background-color: #5584ff;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.Btn:hover {
  background: rgb(83, 168, 255);
}
.CloBtn {
  padding: 10px 20px;
  background-color: #ccc;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.CloBtn:hover {
  background: rgb(83, 168, 255);
}
.Generated {
  margin-top: 20px;
  width: 1060px;
  height: 230px;
  padding: 5px;
  .el-table {
    border: 1px solid #ccc;
    .el-table th.is-leaf {
      border: 1px solid #ccc;
    }
  }
}
.el-input--suffix {
  width: 50px;
}
ul {
  .el-icon-delete {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 20px;
  }
  .el-icon-delete:hover {
    color: #e94c15;
  }
  .el-icon-edit {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
  }
  .el-icon-edit:hover {
    color: #e94c15;
  }
}
.NewAddress {
  //  padding:5px 10px ;
  border: 1px solid #222;
  text-align: center;
  width: 120px;
  height: 40px;
  line-height: 40px;
  //  margin:25px 0 0 -20px;
  font-size: 14px;
  border-radius: 3px;
  //  position: absolute;
  //  top:140px;
  //  left: 20px;
}
.NewAddress:hover {
  background-color: #f2f3f7;
}
.cur {
  background-image: url(//img.alicdn.com/tfs/TB1OVRCRpXXXXaMXFXXXXXXXXXX-237-106.png);
}
.login {
  position: fixed;
  font-size: 24px;
  height: 450px;
  width: 500px;
  background-color: #fff;
  border-radius: 0.25rem;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  z-index: 1000;
  padding: 10px 0 0 30px;
}
.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7; //透明度为70%
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #111111;
}
.el-input__inner {
  padding: 0 !important;
  text-indent: 15px !important;
  border-radius: 0 !important;
}
.el-select-dropdown__item {
  padding: 0 15px !important;
}
</style>
<style lang="scss">
.distpicker-address-wrapper {
  select {
    width: 100px;
  }
}
.el-select-dropdown__list {
  padding-left: 10px;
}
</style>
